// src/views/admin/default/components/AnnouncementCarousel.js

import { useState, useEffect } from 'react';
import { Box, Text, Flex, IconButton, Button, useColorModeValue } from '@chakra-ui/react';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';

const announcements = [
  "Confira nossas novas consultas de veículos!",
  "Novos dados de pontuação de crédito disponíveis.",
  "Atualização de dados de roubos e furtos na sua área.",
  "Consulte nossos novos serviços de verificação de óbito.",
];

const AnnouncementCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % announcements.length);
    }, 2500); // Trocar a cada 2 segundos

    return () => clearInterval(intervalId);
  }, []);

  const nextAnnouncement = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % announcements.length);
  };

  const prevAnnouncement = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + announcements.length) % announcements.length);
  };

  // Chakra Color Mode
  const textColor = useColorModeValue("gray.800", "gray.200");
  const boxBg = useColorModeValue("gray.100", "gray.700");
  const iconColor = useColorModeValue("gray.600", "gray.300");

  return (
    <Box
      p='4'
      bg={boxBg}
      borderRadius='lg'
      shadow='lg'
      width='100%'
      maxW='full'
      mx='auto'
      overflow='hidden'
      border='1px solid'
      borderColor={useColorModeValue("gray.200", "gray.700")}
    >
      <Flex
        align='center'
        justify='space-between'
        width='100%'
        mb='4'
      >
        <IconButton
          icon={<MdArrowBack />}
          onClick={prevAnnouncement}
          aria-label='Previous Announcement'
          variant='outline'
          color={iconColor}
          size='md'
          width='28px' // Reduzido para se ajustar melhor
          height='28px' // Reduzido para se ajustar melhor
          borderRadius='full'
          _hover={{ bg: useColorModeValue("gray.200", "gray.600") }}
          _active={{ bg: useColorModeValue("gray.300", "gray.700") }}
          transition='background-color 0.3s ease'
        />
        <Text
          flex='1'
          textAlign='center'
          fontSize={{ base: 'md', md: 'lg' }} // Tamanho da fonte ajustado para responsividade
          fontWeight='bold'
          color={textColor}
          px='2' // Reduzido o padding horizontal para ajuste em mobile
          whiteSpace='normal'
          overflow='visible'
          textOverflow='clip'
          wordBreak='break-word'
        >
          {announcements[currentIndex]}
        </Text>
        <IconButton
          icon={<MdArrowForward />}
          onClick={nextAnnouncement}
          aria-label='Next Announcement'
          variant='outline'
          color={iconColor}
          size='md'
          width='28px' // Reduzido para se ajustar melhor
          height='28px' // Reduzido para se ajustar melhor
          borderRadius='full'
          _hover={{ bg: useColorModeValue("gray.200", "gray.600") }}
          _active={{ bg: useColorModeValue("gray.300", "gray.700") }}
          transition='background-color 0.3s ease'
        />
      </Flex>

      {/* Adicionando o botão com o texto "Investigação particular pelo WhatsApp" */}
      <Button
        as='a'
        href='https://wa.me/5527988417973' // Substitua pelo link do WhatsApp
        target='_blank'
        rel='noopener noreferrer'
        mt='4'
        width='full'
        bg='blue.500'
        color='white'
        size='md' // Ajustado para tamanho médio em dispositivos móveis
        borderRadius='md'
        _hover={{
          bg: 'blue.900',
        }}
        // Transição suave para todos os estados
        sx={{
          '@keyframes pulse': {
            '0%': { transform: 'scale(1)' },
            '50%': { transform: 'scale(1.015)' },
            '100%': { transform: 'scale(1)' },
          },
          animation: 'pulse 2s infinite',
        }}
      >
        Investigação particular pelo WhatsApp
      </Button>
    </Box>
  );
};

export default AnnouncementCarousel;
