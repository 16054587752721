import React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdHome, MdLock } from 'react-icons/md';

// Admin Imports
import MainDashboard from './views/admin/default';

// Auth Imports
import Login from './views/auth/login/page'; // Caminho corrigido

const routes = [
  {
    name: 'Página Inicial',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    name: 'Login',
    layout: '/auth',
    path: '/login',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <Login />,
  },

  {
    name: 'Sair',
    layout: '/auth',
    path: '/login',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <Login />,
  },
];

export default routes;
