import React from "react";

// Chakra imports
import { Flex } from "@chakra-ui/react";

// Imagem importada
import logoblack from "../../../assets/images/buscaailogoazul.png"; // Caminho atualizado para corresponder à estrutura correta
import { HSeparator } from "../../separator/Separator"; // Caminho atualizado para corresponder à estrutura correta

export function SidebarBrand() {
  return (
    <Flex align='center' direction='column'>
      <img
        src={logoblack}
        alt="Logo"
        style={{
          maxWidth: '175px', // Largura máxima para a imagem
          height: 'auto',    // Altura ajustada automaticamente para manter a proporção
          marginTop: '32px',
          marginBottom: '32px',
          objectFit: 'contain' // Mantém a proporção da imagem dentro das dimensões especificadas
        }}
      />
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
