import React, { useState, useEffect } from 'react';
import { Box, FormControl, FormLabel, Input, Select, Button, useColorModeValue, VStack } from '@chakra-ui/react';
import Popup from './Popup';
import RecentQueries from './RecentQueries'; // Importa o componente de consultas recentes

const SelectAndInput = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [loading, setLoading] = useState(false);
  const [recentQueries, setRecentQueries] = useState([]); // Estado para armazenar as consultas recentes
  const [token, setToken] = useState(null); // Estado para armazenar o token
  const [selectedValue, setSelectedValue] = useState('');
  const [inputValue, setInputValue] = useState('');

  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const placeholderColor = useColorModeValue('gray.500', 'gray.400');

  // Puxa o token do localStorage automaticamente ao carregar o componente
  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      alert('Token não encontrado. Usuário não autenticado.');
    }

    // Carrega consultas do localStorage
    const cachedQueries = JSON.parse(localStorage.getItem('recentQueries')) || [];
    setRecentQueries(cachedQueries);
  }, []);

  const getInputProps = (value) => {
    switch (value) {
      case 'cpf':
        return { label: 'Digite o CPF', maxLength: 11, placeholder: '12345678901', onlyNumbers: true };
      case 'cnpj':
        return { label: 'Digite o CNPJ', maxLength: 14, placeholder: '12.345.567/0001-00', onlyNumbers: true };
      case 'telefone':
        return { label: 'Digite o Telefone', maxLength: 11, placeholder: '(11) 91234-5678', onlyNumbers: true };
      case 'email':
        return { label: 'Digite o Email', maxLength: undefined, placeholder: 'exemplo@dominio.com', onlyNumbers: false };
      case 'obito':
        return { label: 'Digite o Número do Óbito', maxLength: 10, placeholder: '1234567890', onlyNumbers: true };
      case 'placa':
        return { label: 'Digite a Placa do Veículo', maxLength: 7, placeholder: 'ABC1234', onlyNumbers: false };
      default:
        return { label: 'Digite aqui', maxLength: undefined, placeholder: 'Digite algo', onlyNumbers: false };
    }
  };

  const { label, maxLength, placeholder, onlyNumbers } = getInputProps(selectedValue);

  const handleKeyPress = (e) => {
    if (onlyNumbers && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleQuery = async () => {
    if (!inputValue) {
      alert('Por favor, digite um valor antes de consultar.');
      return;
    }

    if (token) {
      let apiUrl = '';
      let requestBody = {};

      // Define a URL da API com base na seleção
      switch (selectedValue) {
        case 'cpf':
          apiUrl = `https://querubimcompany.com/consultations/cpf`;
          requestBody = { cpf: inputValue };
          break;
        case 'cnpj':
          apiUrl = `https://querubimcompany.com/consultations/cnpj`;
          requestBody = { cnpj: inputValue };
          break;
        case 'telefone':
          apiUrl = `https://querubimcompany.com/consultations/phone`;
          requestBody = { phone: inputValue };
          break;
        case 'email':
          apiUrl = `https://querubimcompany.com/consultations/email`;
          requestBody = { email: inputValue };
          break;
        case 'obito':
          apiUrl = `https://querubimcompany.com/consultations/obito`;
          requestBody = { obito: inputValue };
          break;
        case 'placa':
          apiUrl = `https://querubimcompany.com/consultations/vehicle`;
          requestBody = { placa: inputValue };
          break;

        default:
          alert('Seleção inválida.');
          return;
      }

      try {
        setLoading(true);

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        const contentType = response.headers.get('content-type');

        if (response.status !== 201) {
          throw new Error(`Erro na consulta: ${response.statusText}`);
        }

        if (contentType && contentType.includes('application/json')) {
          const result = await response.json();
          if (result?.data) {
            setPopupData(result.data);

            // Adiciona a consulta recente ao estado e usa o inputValue como protocolo
            const newQuery = {
              id: recentQueries.length + 1,
              title: `Consulta ${selectedValue.toUpperCase()}`,
              date: new Date().toLocaleDateString(),
              data: result.data, // Os dados da consulta
              protocol: inputValue, // Usa o inputValue como protocolo
            };

            const updatedQueries = [newQuery, ...recentQueries].slice(0, 3);
            setRecentQueries(updatedQueries);

            // Salva as consultas no localStorage
            localStorage.setItem('recentQueries', JSON.stringify(updatedQueries));
          } else {
            setPopupData({ error: 'Nenhum dado encontrado.' });
          }
        } else {
          const resultText = await response.text();
          setPopupData({ error: `Resposta inesperada da API: ${resultText}` });
        }

        setPopupOpen(true);
      } catch (error) {
        setPopupData({ error: `Erro ao realizar consulta: ${error.message}` });
        setPopupOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const closePopup = () => {
    setPopupOpen(false);
    setPopupData({});
  };

  return (
    <>
      <Box
        p="20px"
        bg={bgColor}
        borderRadius="md"
        boxShadow="sm"
        borderWidth="1px"
        borderColor={borderColor}
      >
        <VStack spacing="20px" align="stretch">
          <FormControl>
            <FormLabel htmlFor="select" color={textColor}>Selecione uma opção</FormLabel>
            <Select
              id="select"
              placeholder="Selecione uma opção"
              onChange={(e) => setSelectedValue(e.target.value)}
              value={selectedValue || ''} // Garante que selectedValue tenha um valor
              bg={bgColor}
              borderColor={borderColor}
              focusBorderColor="brand.500"
              _placeholder={{ color: placeholderColor }}
              size="lg"
              borderRadius="md"
            >
              <option value="cpf">Consultar CPF</option>
              <option value="cnpj">Consultar CNPJ</option>
              <option value="telefone">Consultar Pelo Telefone</option>
              <option value="email">Consultar Pelo Email</option>
              <option value="obito">Consultar Óbito</option>
              <option value="placa">Consultar Placa de Veículo</option>
            </Select>
          </FormControl>

          {selectedValue && (
            <FormControl>
              <FormLabel htmlFor="input" color={textColor}>{label}</FormLabel>
              <Input
                id="input"
                placeholder={placeholder}
                value={inputValue}
                onChange={(e) => setInputValue(onlyNumbers ? e.target.value.replace(/[^0-9]/g, '') : e.target.value)}
                onKeyPress={handleKeyPress}
                bg={bgColor}
                borderColor={borderColor}
                focusBorderColor="brand.500"
                _placeholder={{ color: placeholderColor }}
                size="lg"
                borderRadius="md"
                maxLength={maxLength}
              />
            </FormControl>
          )}

          <Button colorScheme="blue" onClick={handleQuery} isLoading={loading}>Consultar</Button>
        </VStack>
      </Box>

      {/* Componente RecentQueries, passa o estado recentQueries como prop */}
      <Box mt="10px"> {/* Ajustado mt para 10px para diminuir o espaçamento */}
        <RecentQueries recentQueries={recentQueries} />
      </Box>

      <Popup isOpen={isPopupOpen} onClose={closePopup} data={popupData} />
    </>
  );
};

export default SelectAndInput;
