// src/views/admin/default/index.jsx

import React, { useEffect, useState } from 'react';
import { Box } from "@chakra-ui/react";
import { Navigate } from 'react-router-dom';

import RecentQueries from "./components/RecentQueries";
import AnnouncementCarousel from "./components/AnnouncementCarousel";
import SelectAndInput from "./components/SelectAndInput";

export default function UserReports() {
  // Estados e hooks
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const checkAuthentication = () => {
      const token = localStorage.getItem('authToken');
      // Verifica se o token existe e define a autenticação
      if (token) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  // Estado de carregamento ou verificação em progresso
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  // Handlers para SelectAndInput
  const handleSelectChange = (value) => setSelectedValue(value);
  const handleInputChange = (value) => setInputValue(value);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Carousel no topo da página */}
      <Box mb='20px'>
        <AnnouncementCarousel />
      </Box>

      {/* Componente SelectAndInput */}
      <Box mb='20px'>
        <SelectAndInput
          onSelectChange={handleSelectChange}
          onInputChange={handleInputChange}
          selectedValue={selectedValue}
          inputValue={inputValue}
        />
      </Box>

      {/* RecentQueries logo abaixo do SelectAndInput */}
      <Box mb='20px'>
        <RecentQueries />
      </Box>
    </Box>
  );
}
