import React from 'react';
import { Box, Button, Text, VStack, Divider, useColorModeValue, useBreakpointValue, IconButton, Flex } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

const Popup = ({ isOpen, onClose, data, consultaTipo }) => {
  const backdropColor = useColorModeValue('rgba(0, 0, 0, 0.4)', 'rgba(0, 0, 0, 0.6)');
  const isMobile = useBreakpointValue({ base: true, md: false });
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'gray.100');
  const sectionBgColor = useColorModeValue('gray.50', 'gray.700');
  const sectionAltBgColor = useColorModeValue('white', 'gray.800');

  if (!data || Object.keys(data).length === 0) return null;

  const titulo = consultaTipo ? `Dados da Consulta ${consultaTipo}` : 'Dados da Consulta';

  // Função para renderizar seções de dados com agrupamento e alternância de cores
  const renderFormattedData = (data) => {
    return Object.entries(data).map(([label, value], index) => (
      <Box
        key={index}
        p="15px"
        border={`1px solid ${borderColor}`}
        borderRadius="lg"
        bg={index % 2 === 0 ? sectionBgColor : sectionAltBgColor}
        mb="10px"
        boxShadow="sm"
      >
        <Text fontWeight="bold" mb="5px" color={textColor} fontSize="lg" textTransform="capitalize">
          {label.replace(/_/g, ' ')}
        </Text>
        <Divider mb="5px" />
        {renderValue(value)}
      </Box>
    ));
  };

  // Função para renderizar os valores
  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return value.map((item, idx) => (
        <VStack align="start" key={idx} spacing="5px" mb="10px">
          {typeof item === 'object' ? (
            Object.entries(item).map(([itemLabel, itemValue]) => (
              <Text key={itemLabel} color={textColor} fontSize="md">
                <strong>{itemLabel.replace(/_/g, ' ')}:</strong> {renderValue(itemValue)}
              </Text>
            ))
          ) : (
            <Text color={textColor} fontSize="md">{item}</Text>
          )}
        </VStack>
      ));
    } else if (typeof value === 'object' && value !== null) {
      return (
        <VStack align="start" spacing="5px">
          {Object.entries(value).map(([subKey, subValue]) => (
            <Text key={subKey} color={textColor} fontSize="md">
              <strong>{subKey.replace(/_/g, ' ')}:</strong> {renderValue(subValue)}
            </Text>
          ))}
        </VStack>
      );
    } else {
      return <Text color={textColor} fontSize="md">{value}</Text>;
    }
  };

  return (
    isOpen ? (
      <>
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg={backdropColor}
          zIndex="overlay"
          onClick={onClose}
        />
        <Box
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          p={isMobile ? "20px" : "40px"}
          bg={bgColor}
          borderRadius="2xl"
          boxShadow="lg"
          zIndex="overlay"
          maxW="85vw"
          minW={isMobile ? "85%" : "800px"}
          maxH="80vh"
          overflowY="auto"
          transition="all 0.3s ease"
        >
          <IconButton
            icon={<CloseIcon />}
            position="absolute"
            top="10px"
            right="10px"
            onClick={onClose}
            aria-label="Fechar popup"
            variant="ghost"
            _hover={{ bg: 'gray.200' }}
          />

          <Text fontSize={isMobile ? "2xl" : "3xl"} fontWeight="bold" mb="20px" textAlign="center" color={textColor}>
            {titulo}
          </Text>

          <VStack spacing="15px" align="stretch">
            {renderFormattedData(data)}
          </VStack>

          <Flex justifyContent="center" mt="30px">
            <Button onClick={onClose} colorScheme="blue" boxShadow="md" px="6" py="4" _hover={{ boxShadow: 'lg' }}>
              Fechar
            </Button>
          </Flex>
        </Box>
      </>
    ) : null
  );
};

export default Popup;
